<template>
  <van-tabbar
    v-if="show"
    v-model="active"
    active-color="#FE2E46"
    :border="true"
    inactive-color="#999999"
    class="t344"
	@change="select"
  >
   <!-- <van-tabbar-item v-for="(k, key) in item"  :url="k.router" :key="key"> -->
    <van-tabbar-item v-for="(k, key) in item"   >
   
      <template #icon="props">
      <div  v-show="key !== 2" class="di">
        <img
          :src="props.active ? k.icon.active : k.icon.noactive"
          :alt="k.title"
			class="icons"
        />
      <span class="tt">{{ k.title }}</span>
</div>

<div v-show="key === 2" class="di sst">
        <img
          :src="props.active ? k.icon.active : k.icon.noactive"
          :alt="k.title"
          class="tui"
          style="height: 3rem; margin-bottom:10%;"
          
        />
    <span class="tt">{{ k.title }}</span>
</div>
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
export default {
  data() {
    return {
      show: false,
      active: 0,
      item: [
        {
          router: "/Home",
          title: "메인페이지",
          icon: {
            active: "/img/footer/index.png",
            noactive: "/img/footer/index2.png?id=2",
          },
        },
        {
          router: "/Game",
          title: "예약",
          icon: {
            active: "/img/footer/renwu.png",
            noactive: "/img/footer/renwu2.png",
          },
        },
        {
          router: "/Goods",
           title: '매니저 선택',
          icon: {
            active: "/img/footer/home.png",
            noactive: "/img/footer/home.png",
          },
        },
        {
          router: "/Youxi",
          title: "게임 내역",
          icon: {
            active: "/img/footer/rlog.png",
            noactive: "/img/footer/rlog2.png",
          },
        },
        {
          router: "/Mine",
          title: "MY페이지",
          icon: {
            active: "/img/footer/ren.png?id=2",
            noactive: "/img/footer/ren2.png?id=3",
          },
        },
      ],
    };
  },
  methods: {
	  select(e){
		  
		   this.$router.push({ path: this.item[e].router });
	  }
  },
  watch: {
    $route(to) {
      if (to.name == "home") {
        this.active = 0;
        this.show = true;
      } else if (to.name == "game") {
        this.active = 1;
        this.show = true;
      } else if (to.name == "choose") {
        this.active = 2;
        this.show = true;
      } else if (to.name == "video") {
        this.active = 3;
        this.show = true;
      } else if (to.name == "Youxi") {
        this.active = 3;
        this.show = true;
      }else if (to.name == "mine") {
        this.active = 4;
        this.show = true;
      } else if (to.name == "Goods") {
        this.active = 2;
        this.show = true;
      }  else {
        this.show = false;
      }
    },
  },
  created() {
    if (this.$route.name == "home") {
      this.active = 0;
      this.show = true;
    } else if (this.$route.name == "game") {
      this.active = 1;
      this.show = true;
    } else if (this.$route.name == "choose") {
      this.active = 2;
      this.show = true;
    } else if (this.$route.name == "video") {
      this.active = 3;
      this.show = true;
    } else if (this.$route.name == "mine") {
      this.active = 4;
      this.show = true;
    } else {
      this.show = false;
    }
  },
};
</script>

<style lang="less" scoped>
@tabbar-height: 150px;
@tabbar-img: 110px;
.van-tabbar {
  height: @tabbar-height;
  
}
.van-tabbar-item__icon img {
  height: @tabbar-img;
}
.van-tabbar-item {
  font-size: 26px;
}
.tui {
 
 position:relative;
 background-color: #FFF;
 padding: 10%;
 border-radius: 100%;
  
}
[class*="van-hairline"]::after {
  border: none !important;
}
</style>
<style>
.van-tabbar-item--active{
  background-color: inherit !important;
}
.di{
  display:flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  
}
.tt{
      text-align: center;
	margin-top: 20px;
}
.sst .tt{
	margin-top: 0;
}
.t344{
  
}
.sst{
  position:relative;
  top:-18%;
}
.icons{
	width: 45px !important;
	height: 45px !important;
	
}
</style>