<template>
  <div id="app">
    <router-view></router-view>
	

    <Footer />
  </div>
</template>

<script>
import Footer from "./common/Footer";
export default {
  name: "app",
  components: {
    Footer,
  },
  data() {
    return {
      status: 0,
    };
  },
  methods: {
    getBaseInfo() {
      this.$http({
        method: "get",
        url: "base_info",
      }).then((res) => {
        console.log("res===🚀===>", res);
        localStorage.setItem("see_num", res.data.see_num);
        localStorage.setItem("bank_name", res.data.bank_name);
        localStorage.setItem("bank_number", res.data.bank_number);
        if (!localStorage.getItem("token")) {
          this.$router.push({ path: "/Login" });
        }
        this.$store.commit("setBaseInfoValue", res.data);
      });
    },
  },
  created() {
    this.getBaseInfo();
  },
  mounted() {
    function adjustFontSize() {
      // 获取屏幕宽度
      const screenWidth = window.innerWidth;

      // 检测是否为移动设备
      const isMobile =
        /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );

      // 如果是移动设备，移除font-size设置
      if (isMobile) {
        document.documentElement.style.fontSize = "";
        // PC端基于有效屏幕宽度调整font-size
      } else {
        // 如果屏幕宽度大于750px，将屏幕宽度视为750px
        const effectiveWidth = screenWidth > 750 ? 750 : screenWidth;

        // PC端基于有效屏幕宽度调整font-size
        const fontSize = effectiveWidth / 32; // 以屏幕宽度的1/10作为基准
        document.documentElement.style.fontSize = fontSize + "px";
      }
    }

    // 在窗口大小改变时调整font-size
    window.addEventListener("resize", adjustFontSize);

    // 初始化字体大小
    adjustFontSize();
  },
};
</script>

<style>
body .van-toast {
  font-size: 38px;
  padding: 30px;
  line-height: 50px;
  width: 230px;
}
body .van-toast .van-toast__icon {
  font-size: 50px;
}
*,
:after,
:before {
  box-sizing: border-box;
}
</style>
