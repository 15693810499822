<template>
	<div class="container page">
		<!-- <div class="header">
      <van-nav-bar title="시스템 알림" class="nav-bar">
        <template #left>
          <van-icon name="arrow-left" color="#fff" @click="back()" />
        </template>
      </van-nav-bar>
    </div> -->

		<div  class="tx_top"><img  src="/img/tx/result.png" class="result"  @click="back()">
			<div  class="tx_top_title">시스템 알림</div>
		</div>

		<div class="content">
			<van-pull-refresh v-model="isLoading" @refresh="onRefresh" success-text="새로고침 성공">
				<template #pulling> 새로고침하려면 아래로 스크롤하세요. </template>
				<template #loosing> 새로고침하려면 아래로 스크롤하세요. </template>
				<template #loading> 로드 중 </template>
				<div class="listItem" v-for="(v, key) in notice" :key="key">
					<div class="listTitle">{{ v.name }}</div>
					<div class="listTime">
						<div class="listTimeText">{{ v.create_time }}</div>
					</div>
					<div class="listContent html">
						<p style="padding: 0;margin: 0;color: #000;">{{ v.text }}<br /></p>
					</div>
					
				</div>
			</van-pull-refresh>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				isLoading: false,
				notice: {},
				loading: false,
				finished: false,
			};
		},
		methods: {
			back() {
				return window.history.back();
			},
			getNoticeList() {
				this.$http({
					method: "get",
					url: "sys_get_notice_list",
				}).then((res) => {
					console.log(res);
					this.notice = res.data;
				});
			},
			onRefresh() {
				setTimeout(() => {
					this.$toast("새로고침 성공");
					this.isLoading = false;
					this.getNoticeList();
				}, 500);
			},
		},
		created() {
			this.getNoticeList();
		},
	};
</script>

<style lang="less" scoped>
	@import "../../assets/css/base.css";

	::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
		color: #000000;
		font-size: 35px;
	}

	.page {
		
		
		background-image: url(/img/login/bg.png);
		    background-size: 100% 100%;
		    background-repeat: no-repeat;
	}

	::v-deep .van-loading__text {
		color: #000000;
		font-size: 35px;
	}

	.container .content {
		height: calc(100% - 20px);
		overflow: auto;
	}

	.container .content .listItem {
		margin-bottom: 20px;
		padding: 20px 20px 0;
		position: relative;
		color: #000;
		
		
		    background-size: 100% 100%;
		    background-repeat: no-repeat;
			width: 93%;
			margin: 0 auto;
			margin-top: 5%;
			border-radius: 13px;
			padding-bottom: 60px;
			padding-top: 60px;
			background-color: #fff;
		
	}

	.container .content .listItem .listTitle {
		font-size: 32px;
		color: #FF1A6D;
	}

	.container .content .listItem .listContent {
		
		padding: 5px 0;
		font-size: 29px;
		color: #fff;
	}

	.container .content .listItem .listTime {
		display: flex;
		flex-direction: row;
		align-items: center;
		
		
		margin-top: 3%;
		margin-bottom: 3%;
	}

	.container .content .listItem .listTime .listTimeText {
		color: #999999;
		font-size: 33px;
	}
	.tx_top{
		display: flex;
		width: 95%;
		margin: 0 auto;
		color: #FF1A6D;
		font-size: 1.4rem;
		font-weight: 500;
		align-items: center;
		margin-top: 5%;
		position: relative;
	}
	.result{
		width: 25px;
		height: 40px;
	}
	.tx_top_title{
		position: absolute;
		 top: 50%;
		  left: 50%;
		  transform: translate(-50%, -50%);
	color: #fff;
	}
</style>