var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container page"},[_c('van-nav-bar',{staticClass:"nav-bar",attrs:{"title":"기본 정보"},scopedSlots:_vm._u([{key:"left",fn:function(){return [_c('van-icon',{attrs:{"name":"arrow-left","color":"#fff"},on:{"click":function($event){return _vm.back()}}})]},proxy:true}])}),_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"item van-hairline--bottom",on:{"click":function($event){return _vm.openHerderImg()}}},[_c('div',{staticClass:"left"},[_vm._v("프로필")]),_c('div',{staticClass:"right"},[_c('img',{attrs:{"src":require('../../assets/999.png')}}),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c('van-popup',{style:({ height: '50%' }),attrs:{"position":"bottom","round":""},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('div',{staticClass:"avatarbox"},[_c('div',{staticClass:"title van-hairline--bottom"},[_c('van-icon',{attrs:{"name":"cross"},on:{"click":function($event){_vm.show = false}}}),_c('div',{staticClass:"text"},[_vm._v("아바타 선택")]),_c('div',{staticClass:"btnok",on:{"click":function($event){return _vm.check()}}},[_vm._v("확신하는")])],1),_c('div',{staticClass:"content"},_vm._l((30),function(item,index){return _c('van-image',{key:index,class:{
              choose:
                _vm.isActive ===
                'https://zxbuk.oss-cn-hongkong.aliyuncs.com/images/avatar/avatar' +
                  item +
                  '.png',
            },attrs:{"round":"","src":require(`../../assets/${index + 1}.jpg`)},on:{"click":function($event){return _vm.select_header_img(
                'https://zxbuk.oss-cn-hongkong.aliyuncs.com/images/avatar/avatar' +
                  item +
                  '.png'
              )}}})}),1)])]),_c('div',{staticClass:"item van-hairline--bottom",on:{"click":function($event){return _vm.toSetName()}}},[_c('div',{staticClass:"left"},[_vm._v("실제 이름")]),_c('div',{staticClass:"right"},[_c('span',{staticClass:"desc"},[_vm._v(_vm._s(this.userInfo.name ? this.userInfo.name : "설정되지 않음"))]),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c('div',{staticClass:"item van-hairline--bottom",on:{"click":function($event){return _vm.toSetSex()}}},[_c('div',{staticClass:"left"},[_vm._v("성별")]),_c('div',{staticClass:"right"},[_c('span',{staticClass:"desc"},[_vm._v(_vm._s(this.userInfo.sex !== "0" ? this.userInfo.sex === "1" ? "남성" : "여성" : "알려지지 않은"))]),_c('van-icon',{attrs:{"name":"arrow"}})],1)]),_c('div',{staticClass:"item van-hairline--bottom",on:{"click":function($event){return _vm.toSetBank()}}},[_c('div',{staticClass:"left"},[_vm._v("결제 방법")]),_c('div',{staticClass:"right"},[_c('span',{staticClass:"desc"},[_vm._v(_vm._s(this.isBank ? "강제" : "사용 불가"))]),_c('van-icon',{attrs:{"name":"arrow"}})],1)])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }