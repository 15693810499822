<template>
  <div class="convention-hall page ppcd">
	  
	  
		 <div class="tx_top">
			<img  src="/img/tx/result.png" class="result" @click="back()"/>
			<div class="tx_top_title">출금기록</div>
			
			
		 </div>	
	 
	 
	 

	 
	 
	<div>
			<div class="rows">
				<div class="bg"  
				  v-for="(v, key) in list"
				  :key="key">
				  <div class="top">
					  <div class="top_a">
						거절사유:{{v.desc==''?'현금 인출':v.desc}}    
					  </div>
					  <div class="top_b">
					  		금액: -{{ v.money }}			  
					  </div>
					  
				  </div>
				  
				  <div class="top">
					  <div style="display: flex;justify-content: space-between;width: 100%;align-items: flex-end;">
						  <div class="left_a">
						  		<div>
						  					제출 시간：{{ v.create_time }}	   
						  		</div>
								   <div>
										완료 시간：{{ v.update_time }}								   
								   </div>
						  </div>
						 
						 
						 
						  <div  class="right_a1" style="padding: 2%; padding-left: 4%;padding-right: 4%; " v-if="v.status==1">
						  		심사 중		   
						  </div>
						  
						  
						  <div  class="right_a" style="padding: 2%; padding-left: 4%;padding-right: 4%; " v-if="v.status==2">
						  		성공		   
						  </div>
						  
						   <div  class="right_a2" style="padding: 2%; padding-left: 4%;padding-right: 4%; " v-if="v.status==3">
						  		거부됨		   
						  </div>
					  
					  
					  
					  </div>
					   
					  
				  </div>
						
					
				</div>
				
			</div>
		
	</div>	
	
	
  </div>
</template>

<script>
export default {
  data() {
    return {
      isLoading: false,
      list: [],
    };
  },
  methods: {
    back() {
      return window.history.back();
    },
    onRefresh() {
      setTimeout(() => {
        this.$toast("새로고침 성공");
        this.isLoading = false;
      }, 500);
    },
    getUserWithdrawList() {
      this.$http({
        method: "get",
        url: "user_get_withdraw_list",
      }).then((res) => {
        if (res.code === 200) {
          this.list = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({ path: "/Login" });
    } else {
      this.getUserWithdrawList();
    }
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/css/base.css";
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}
.nav-bar{
	background-color: inherit !important;
}
::v-deep .van-loading__text {
  color: #000000;
  font-size: 35px;
}
.container .main {
  position: relative;
  overflow: auto;
  background-color: #f2f2f5;
  height: 100%;
  padding: 0 10px;
}

.item_list {
  padding: 15px 15px;
  margin: 30px 10px;
  background: #fff;
  border-radius: 10px;
  line-height: 60px;
}

.item_list .topInfo span {
  flex: 1;
  font-size: 35px;
  font-weight: 700;
  color: #ff253f;
}
.item_list .time span {
  flex: 1;
  font-size: 25px;
  font-weight: 500;
  color: #000;
}

.item_list .topInfo span:last-child {
  float: right;
}
.item_list .desc span {
  font-size: 25px;
  font-weight: 700;
  color: #9b9b9b;
}
</style>
<style lang="less" scoped>
.page {
  position: absolute !important;
  top: 0;
  left: 0;
  right: 0;
      background-image: url(/img/login/bg.png);
      background-size: 100% 100%;
      background-repeat: no-repeat;
  
}
.nav-bar {
  background: linear-gradient(90deg, #775fd9, #c24491);
  height: 100px;
}
.van-nav-bar {
  line-height: 50px;
}

::v-deep .van-nav-bar__title {
  max-width: 60%;
  margin: 0 auto;
  color: #ffffff;
  font-size: 35px;
}
::v-deep .van-nav-bar__content {
  height: 100px;
}
.van-sidebar {
  width: 180px;
}
.van-sidebar-item--select::before {
  left: 10px;
  height: 44%;
  background-color: #775fd9;
  border-radius: 5px;
  width: 10px;
}

.van-sidebar-item--select {
  color: #775fd9;
  font-size: 35px;
  text-align: center;
}
.van-sidebar-item {
  font-size: 26px;
  text-align: center;
  padding: 40px 10px;
  background-color: #ffffff;
}
.van-sidebar-item--select,
.van-sidebar-item--select:active {
  background-color: #f2f2f5;
}
.convention-item {
  display: flex;
  align-items: center;
  height: calc(100% - 15px);
}
.convention-hall {
  display: flex;
  flex-direction: column;
  bottom: 20px;
  
}
.convention-item .left {
  height: 100%;
  background-color: #fff;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.convention-item .right {
  height: 100%;
  flex: 1;
  background-color: #f2f2f5;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.convention-item .right .list-wrapper {
  padding: 20px 20px;
  min-height: 800px;
}
.convention-item .right .list-wrapper .game_item_img {
  width: 200px;
  height: 200px;
}
.convention-item .right .list-wrapper span {
  margin-top: 10px;
  font-size: 30px;
  color: #c24491;
  line-height: 40px;
}
.convention-item .right .list-wrapper span:last-child {
  margin-top: 10px;
  font-size: 24px;
  color: #000;
}
.van-grid-item {
  padding: 20px;
}
::v-deep .van-grid-item__content--center {
  border-radius: 15px;
  box-sizing: border-box;
}
::v-deep .van-sidebar-item__text {
  width: 100%;
}
::v-deep .van-image__img {
  border-radius: 40px;
}
::v-deep .van-pull-refresh__track .van-pull-refresh__head * {
  color: #000000;
  font-size: 35px;
}
.tx_top{
	display: flex;
	width: 95%;
	margin: 0 auto;
	color: #fff;
	font-size: 1.4rem;
	font-weight: 500;
	align-items: center;
	margin-top: 5%;
	position: relative;
}
.result{
	width: 25px;
	height: 40px;
}
.tx_top_title{
	position: absolute;
	 top: 50%;
	  left: 50%;
	  transform: translate(-50%, -50%);

}
.shijian{
	width: 80%;
	margin: 0 auto;
	display: flex;
	color: #fff;
	font-size: 1.1rem;
	margin-top: 5%;
}
.az{
	width: 50%;
	text-align: center;
	display: flex;
	justify-content: center;
	align-items: center;
	
}
.rows{
	position: relative;
	height: 100%;
	overflow-x: scroll;
}
.bg{
	
	
	
	
	width: 90%;
	margin: 0 auto;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	margin-top: 5%;
	display: flex;
	padding: 2%;
	background-color: #fff;
	border-radius: 8px;
	flex-direction: column;


}
.gs{
	width: 160px;
	height: 160px;
}
.rg{
	margin-left: 5%;
}
.class1{
	color: #fff;
	font-size: 30px;
}
.class2{
	color: #696572;
	font-size: 30px;
	margin-top: 40%;
}
.select{
	background-image: url('/img/game/yuan.png');
	background-size: 100% 40%;
	background-repeat: no-repeat;
	  background-position: bottom;

	
}
.rows .top{
	display: flex;
	justify-content: space-between;
	width: 95% !important;
	align-items: flex-start;
	
}
.top_a{
	color: #333333;
	font-weight: 800;
	width: 60%;
	word-wrap: break-word; /* 允许在单词内换行 */
	
	
}
.top_b{
	color: #DE3047;
	font-weight: 600;
	
}
.left_a{
	color: #333333;
	font-size: 25px;
	
}
.right_a{
	background-color: #E6FFEC;
	color: #39B154;
}
.right_a1{
	background-color: #F6F4F4;
	color: #999999;
}
.right_a2{
	background-color: #FEF6EA;
	color: #F68703;
}
</style>
<style>
	.ppcd .van-image__img{
		border-radius: 5px !important;
	}
.ppcd{
	overflow: scroll;
}
</style>