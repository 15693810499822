<template>
  <div class="mine page pp1">


    <div class="page-bg"></div>
    <div class="wrapper">

	<div class="tx_top">
		<img  src="/img/tx/result.png" class="result" @click="back()"/>
		<div class="tx_top_title">현금 인출</div>
		<div class="jilu" @click="nato('./WithdrawRecord')">기록</div>
		
	</div>	
	
<div class="ipu">
	<div class="cjp">현금 인출 금액</div>
	<div class="yuan">
		
		<div class="hs">
			<van-field
			 type="number"
			  v-model="withdraw_money"
			  clearable
			  input-align="left"
			  class="input ip_yue"
			  placeholder="입금액을 입력해주세요"
			  input-class="custom-input-color"
			style="width: 80%;"
			/>
			
			<div style="width: 20%;display: flex;">
				<div class="xian2"></div>
				<div class="KRW">
					KRW
				</div>
			</div>
		</div>
		
		
	</div>
	<div class="uu">
		
		계좌 잔액:{{ userInfo.money }} KRW
	</div>
	
</div>	
<div class="ipu">
	<div class="cjp">현금 인출 방식</div>
	<div class="bank_top bank">
		<div>
			은행계좌
		</div>
		
		<div>
			<!-- <img src="/img/chong/go.png" class="go"/> -->
		</div>
		
	</div>

	<div class="bank_top bank bank2">
		<div>
			은행명
		</div>
		
		<div v-if="listdata.bankinfo" style="color: #666666; font-weight: 300;">
			
			 {{listdata.bankinfo.bankinfo}} 
		</div>
		
	</div>
	
	<div class="bank_top bank bank2">
		<div>
			환급 계좌번호
		</div>
		
		<div v-if="listdata.bankinfo" style="color: #666666; font-weight: 300;">
			 {{listdata.bankinfo.bankid}} 
		</div>
		
	</div>
	
	
	<div class="bank_top bank ">
		<div>
			이름
		</div>
		
		<div v-if="listdata.userinfo" style="color: #666666; font-weight: 300;">
			 {{listdata.userinfo.name}} 
		</div>
		
	</div>
		<div class="cjp" style="margin-top: 5%;">비밀번호 입력</div>
		
		<div class="yuan">
		
			
			
			<van-field
			 type="password"
			  v-model="paypassword"
			  clearable
			  input-align="left"
			  class="input ip_yue"
			  placeholder="여기에 입력"
			  input-class="custom-input-color"
			  style="display: flex;align-items: center; width: 100%;"
			
			/>
			
		</div>

</div>		
	
<div class="ti" @click="doWithdraw()">
	출금
</div>	
<div class="text">
		<div class="srf">
			<div class="duan"></div>
			<div style="opacity: 0.5;"> </div>
		</div>
		<div class="rows" style="opacity: 0.5;">
 
			<br/> <br/>
			 <br/> <br/>
			 <br/>

			

		</div>	
		
	
</div>	



</div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      userInfo: {},
      show: false,
      payLoading: false,
      form: {
        bank_number: localStorage.getItem("bank_number"),
        bank_name: localStorage.getItem("bank_name"),
        amount: "",
      },
      menu_top: 40,
      isLoading: false,
	  withdraw_money:0,
	  paypassword:'',
	  listdata:{
		  bankinfo:{
			  bankinfo:'',
			  bankid:''
			  
		  },
		  userinfo:{
			  name:''
		  }
	  }
	  
    };
  },
  methods: { 
	  bank(){
		  this.$http({
		    method: "post",
		    url: "getBankBind",
		  }).then((res) => {
		    if (res.code === 200) {
		       this.listdata=res.data
		    } else if (res.code === 401) {
		      this.$toast(res.msg);
		    }
		  });
	  },
	  doWithdraw() {
	    if (this.withdraw_money <= 0) {
	      this.$toast("정확한 금액을 입력해주세요.");
	      return;
	    }
	  
	    if (!this.paypassword) {
	      this.$toast("암호를 입력하십시오.");
	      return;
	    }
	  
	    this.$http({
	      method: "post",
	      data: {
	        money: this.withdraw_money,
	        paypassword: this.paypassword,
	      },
	      url: "user_set_withdraw",
	    }).then((res) => {
	      if (res.code === 200) {
	        console.log("res===🚀===>", res);
	        // location.reload()
	        this.$toast(res.msg);
	        this.getUserInfo();
	        this.getUserWithdrawRole();
			setTimeout(()=>{
				location.reload()
			},500)
	      } else if (res.code === 401) {
	        this.$toast(res.msg);
	      }
	    });
	  },
	  nato(url){
		this.$router.push({
		  path: url,
		});  
	  },
	  back(){
		  this.$router.push({
		    path: "./Mine",
		  });
	  },
    pay() {
      if (!this.form.amount) return this.$toast("금액을 입력해주세요.");
      this.payLoading = true;
      this.$http({
        method: "post",
        data: this.form,
        url: "recharge_apply",
      })
        .then((res) => {
          if (res.code === 200) {
            this.show = false;
            this.$toast("검토를 위해 제출됨");
          } else {
            this.show = false;
            this.$toast("고객 서비스에 문의하십시오");
          }
        })
        .catch(() => {
          this.show = false;
          this.$toast("고객 서비스에 문의하십시오");
        });
    },
    refresh() {
      this.isLoading = true;
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem("token")) {
          this.$toast("다운로드 중");
        } else {
          this.$router.push({
            path: "/Login",
          });
        }
      }, 500);
    },
    exit() {
      this.$toast("작업 목록을 작성하고 입력하세요.");
    },
    showSetting() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/Setting",
        });
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
    },
    toNotice() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/Notice",
        });
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
    },
    onRefresh() {
      setTimeout(() => {
        this.isLoading = false;
        if (localStorage.getItem("token")) {
          this.getUserInfo();
          this.$toast("새로고침 성공");
        } else {
          this.$router.push({
            path: "/Login",
          });
        }
      }, 500);
    },
    doLogin() {
      if (localStorage.getItem("token")) {
        this.$router.push({
          path: "/Infomation",
        });
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
    },
    doPay() {
      if (localStorage.getItem("token")) {
        this.show = true;
      } else {
        this.$router.push({
          path: "/Login",
        });
      }
      // this.$toast("Vui lòng liên hệ bộ phận chăm sóc khách hàng để nạp tiền");
      // return false;
    },
    doWithdrawal() {
      this.$http({
        method: "get",
        url: "user_get_bank",
      }).then((res) => {
        if (res.data.is_bank) {
          this.$router.push("withdraw");
        } else {
          this.$router.push("Setbank");
          this.$toast.fail("포인트환급 은행을 등록해주세요");
        }
      });
    },
    toService() {
      if (this.$store.getters.getBaseInfo.iskefu == 1) {
        this.$router.push("ServiceOnline");
      } else {
        this.$toast.fail("지원을 받으려면 고객 서비스에 문의하세요");
      }
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          console.log("userInfo===🚀===>", this.userInfo);
          this.menu_top = 15;
          if (this.userInfo.status !== 1) {
            this.$toast("오프라인 계정");
            localStorage.clear();
            this.$router.push({
              path: "/Login",
            });
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (localStorage.getItem("token")) {
      this.getUserInfo();
	  this.bank()
    } else {
      this.userInfo.username = "로그인 회원가입";
      this.userInfo.ip = "로그인하시면 더 많은 서비스를 즐기실 수 있습니다";
      this.userInfo.header_img = "img/mine/avatar.png";
	  
    }
  },
};
</script>

<style lang="less" scoped>
.pay-content {
  width: 80vw;
  height: 530px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep .van-cell {
    padding: 4.333vw 2.133vw;
  }

  ::v-deep .van-button--normal {
    padding: 4vw 2vw;
  }
}

.page {
 
  background-color: initial !important; 
}
.van-cell {
 position:inherit !important;
}
.mine {
 
  background:inherit  !important;
 
height: 100% !important;
}
.wrapper{
	height: 100%;
	background-image:url('/img/login/bg.png');
	background-size: 100% 100%;
	   background-repeat: no-repeat;
	   overflow-y: scroll;
	   
}


.tx_top{
	display: flex;
	width: 95%;
	margin: 0 auto;
	color: #FF1A6D;
	font-size: 1.4rem;
	font-weight: 500;
	align-items: center;
	padding-top: 5%;
	position: relative;
	justify-content: space-between;
}
.result{
	width: 25px;
	height: 40px;
}
.tx_top_title{
	position: absolute;
	
	  left: 50%;
	  transform: translate(-50%);
	color: #fff;
}
.yue{
	width: 95%;
	margin: 0 auto;
	background-image: url('/img/tx/yue.png');
	height: 7rem;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-top: 5%;
	display: flex;
	align-items: center;
	padding-left: 5%;
}
.jin_title{
	color: #fff;
}
.jin_jin{
	font-size: 52px;
	color: #fff;
	font-weight: 700;
	margin-top: 10%;
}
.ipu{
	width: 95%;
	margin: 0 auto;

	
	background: #fff;
	border-radius: 8px;
	
	
	margin-top: 5%;
	padding: 4%;
}
.cjp{
	color: #333333;
	font-weight: 600;
}
.yuan{
	background: #F6F6F6;
	border-radius: 12px;
	
	width: 100%;
	height: 2.8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 3%;
	padding-right: 6%;
	margin-top: 5%;
}
.hs{
	display: flex;
	align-items: center;
	width: 100%;
	    justify-content: flex-end;
}
.ip_yue{
	background-color: initial !important; 
	height: 100%;
	border: 0;
	color: #999999;
	width: 65%;
	font-size: 1rem;
}
.close{
	width: 40px;
	height: 40px;
	
}
.KRW{
	color: #666666;

}
.xian2{
	width: 2px;
	height: 100%;
	background-color: #666666;
	height: 1rem;
	margin-left: 10%;
	margin-right: 10%;
}
.ti{
	width: 95%;
	margin: 0 auto;
	background: linear-gradient(90deg, #FF807B, #FF8FB8);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	height: 3.5rem;
	margin-top: 5%;
}
.text{
	width: 95%;
	margin: 0 auto;
	margin-top: 8%;
	color: #FFFFFF;
	
	font-size: 30px;
}
.rows{
	margin-bottom: 5%;
	margin-top: 5%;
	line-height: 2;
	padding-bottom: 20%;
}
.uu{
	margin-top: 5%;
	color: #666666;
	font-size: 0.8rem;
}
.duan{
	background-color: #fff;
	width: 8px;
	height: 40px;
	border-radius: 8px;
	margin-right: 3%;
	position:relative;
}
.srf{
	display: flex;
	align-items: center;
}
.go{
	width: 15px;
	height: 20px;
}
.bank{
	display: flex;
	color: #333333;
	font-weight: 600;
	margin-top: 5%;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #EEEEEE;
	padding-bottom: 5%;
}
.bank2{
	border: 0;
	color: #666666;
}
.jilu{
	color:#fff;
	font-size: 35px;
}
</style>
<style>
	.pp1 .van-field__control {
	  color: #000; /* 红色字体 */
	}
	
	.pp1 .van-cell:after{
		border: 0px !important;
	}
</style>