<template>
  <div class="mine page pp1" >


    <div class="page-bg"></div>
    <div class="wrapper">

	<div class="tx_top">
		<img  src="/img/tx/result.png" class="result" @click="back()"/>
		<div class="tx_top_title">포인트 출금</div>
		
		
	</div>	
	
<div class="ipu">
	<div class="lis">
		<div class="cjp">은행명</div>
		<div class="yuan"   >
			
			<van-field
			  v-model="bank"
			class="input ip_yue"
			
			  placeholder="은행명"
			  @click="showSelectBanks()"
			/>
		
			
			
			
		</div>
	</div>
	<div class="lis">
		
		
			<div class="cjp">은행계좌 번호</div>
			<div class="yuan">
				
				<van-field
				  v-model="bankid"
				  class="input ip_yue"
				  
				  type="digit"
				  placeholder="은행 계좌 번호"
				/>
			
				
			</div>
			
	
	</div>
	
	<div class="lis">
		
			<div class="cjp">예금주 성함</div>
			<div class="yuan">
			<van-field
			  v-model="name"
			  class="input ip_yue"
			  
			 
			  placeholder="예금주 성함"
			/>
				
				
				
			</div>
			
		
	</div>
	
	
	<div class="lis">
		
			<div class="cjp">비밀번호 입력</div>
			<div class="yuan">
			
				<van-field
				  v-model="opw"
				  class="input ip_yue"
				  
				  type="password"
				  placeholder="비밀번호 입력"
				/>
				
				
			</div>
			
		</div>
		
		
		<div class="lis">
			
				<div class="cjp">암호 확인</div>
				<div class="yuan">
				
					<van-field
					  v-model="tpw"
					  class="input ip_yue"
					  
					  type="password"
					  placeholder="암호 확인"
					/>
					
					
				</div>
				
			</div>
		
	
		
</div>
	


	
<div class="ti"  @click="bindCard()">
	제출
</div>	



</div>
 <van-popup
      v-model="showBank"
      round
      position="bottom"
      :style="{ height: '35%' }"
    >
      <van-picker
        show-toolbar
        :columns="banks"
        @confirm="onConfirm"
        @cancel="onCancel"
        confirm-button-text="확인"
        cancel-button-text="취소"
      />
    </van-popup>
  </div>
</template>

<script>
export default {
  data() {
    return {
      banks: [],
      showBank: false,
      userInfo: {},
      bankid: "",
      bank: "",
	  name:'',
	   opw:'',
	   'tpw':''
    };
  },
  methods: {
    back() {
      // return window.history.back();
      this.$router.push({
        path: "./Mine",
      });
    },
	save2(result) {
	 
	  if (this.name === "" || this.name === null || this.name === undefined) {

		return  result({'code':404,'msg':'당신의 이름을 입력 해주세요'});
	    
	  }
	  this.$http({
	    method: "get",
	    data: {
	      name: this.name,
	    },
	    url: "user_set_name",
	  }).then((res) => {
		 
	    if (res.code === 200) {
	      this.getUserInfo();
	      this.name = this.userInfo.name;
	
	      // setTimeout(() => {
	      // 	this.$toast(res.msg);
	      // }, 500)
	
	     return  result({'code':'200','msg':''});
	    } else if (res.code === 401) {
	      
		    return result({'code':'404','msg':res.msg});
	    }
	  });
	},
	setPayPassword(result) {
		//BindCard
		//this.oshowKeyboard = false;
		//this.tshowKeyboard = false;
		
		
		
	
			this.$http({
				method: 'post',
				data: {
					paypassword: this.opw
				},
				url: 'user_set_paypw'
			}).then(res => {
				if (res.code === 200) {
					// setTimeout(() => {
					// 	this.$toast(res.msg);
					// }, 500);
					return  result({'code':200,'msg':''});
					
	
				} else if (res.code === 401) {
					
					return  result({'code':404,'msg':res.msg});
				}
			})
		
	},
    bindCard() {
      if (this.userInfo.bankid) {
        this.$toast("은행카드번호를 입력해주세요");
        return true;
      }
      if (this.bank === "" || this.bank === null || this.bank === undefined) {
        this.$toast.fail("은행을 선택해주세요");
        return false;
      }
	  
	 if (this.name === "" ) {
	   this.$toast.fail("카드 소지자 이름을 입력하십시오.");
	   return false;
	 } 
	 if (this.opw === "" ) {
	   this.$toast.fail("현금 인출 비밀번호를 입력하십시오.");
	   return false;
	 } 
	 
	 
	 if (this.opw.length !== 4) {
	 	//this.oshowKeyboard = true;
	 	//this.$toast("완료해주세요");
		  this.$toast.fail("출금 비밀번호는 4자리만 가능합니다");
		return false;
	 	
	 }
	 
	 
	 	
	 if (this.tpw.length !== 4) {
	 	 this.$toast.fail("출금 비밀번호는 4자리만 가능합니다");
		 return false;
		 
	 	
	 }
	 
	 if (this.opw !== this.tpw) {
		 this.$toast.fail("출금 비밀번호가 일치하지 않습니다");
		 return false;
		 
	 	
	 }
	 
	 
	  let li=this.save2(data=>{
		  
		  if(data.code==404){
		  		  return this.$toast.fail(data.msg);
		  }else{
			  let li2=this.setPayPassword(data2=>{
				  
				  if(data2.code==404){
				  		  return this.$toast.fail(data2.msg);
				  }else{
					  
					  this.$http({
					    method: "post",
					    data: {
					      bankid: this.bankid,
					      bank: this.bank,
					  		  
					  		  
					    },
					    url: "user_set_bank",
					  }).then((res) => {
					    if (res.code === 200) {
					      this.$router.push({
					        path: "/Mine",
					      });
					      this.$toast(res.msg);
					    } else if (res.code === 401) {
					      this.$toast(res.msg);
					    }
					  });
				  }
				  
			  })
			  
			 
			  
		  }
		  
	  })
	 
	  
	 
	  
	
      
    },
    showSelectBanks() {
      this.showBank = true;
    },
    getUserInfo() {
      this.$http({
        method: "get",
        url: "user_info",
      }).then((res) => {
        if (res.code === 200) {
          this.userInfo = res.data;
          this.name = res.data.name;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    getBankList() {
      this.$http({
        method: "get",
        url: "sys_get_banks",
      }).then((res) => {
        if (res.code === 200) {
          this.banks = res.data;
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
    onConfirm(value) {
      this.bank = value.text;
      this.showBank = false;
    },
    onCancel() {
      this.showBank = false;
    },
    getUserBankInfo() {
      this.$http({
        method: "get",
        url: "user_get_bank",
      }).then((res) => {
        if (res.code === 200) {
          if (res.data.is_bank) {
            this.is_bind = true;
          } else {
            this.is_bind = false;
          }
        } else if (res.code === 401) {
          this.$toast(res.msg);
        }
      });
    },
  },
  created() {
    if (!localStorage.getItem("token")) {
      this.$router.push({
        path: "/Login",
      });
    } else {
      this.getUserInfo();
      this.getBankList();
      this.getUserBankInfo();
    }
  },
};
</script>

<style lang="less" scoped>
	.page {
	    width: 100%;
	  
	    height: 100%;
	}
.pay-content {
  width: 80vw;
  height: 530px;
  padding: 50px;
  display: flex;
  align-items: center;
  justify-content: center;

  ::v-deep .van-cell {
    padding: 4.333vw 2.133vw;
  }

  ::v-deep .van-button--normal {
    padding: 4vw 2vw;
  }
}


.mine {

  bottom: 10px;
  background: #f2f2f5;
 
height: 100% !important;
}
.wrapper{
	background-image:url('/img/login/bg.png');
	background-size: 100% 100%;
	   background-repeat: no-repeat;
	   width: 100%;
	   height: 100%;
	   overflow: scroll;
}


.tx_top{
	display: flex;
	width: 95%;
	margin: 0 auto;
	color: #FF1A6D;
	font-size: 1.4rem;
	font-weight: 500;
	align-items: center;
	padding-top: 5%;
	position: relative;
}
.result{
	width: 25px;
	height: 40px;
}
.tx_top_title{
	position: absolute;
	
	  left: 50%;
	  transform: translate(-50%);

	color: #fff;
}
.yue{
	width: 95%;
	margin: 0 auto;
	background-image: url('/img/tx/yue.png');
	height: 7rem;
	background-size: 100% 100%;
	background-repeat: no-repeat;
	margin-top: 5%;
	display: flex;
	align-items: center;
	padding-left: 5%;
}
.jin_title{
	color: #fff;
}
.jin_jin{
	font-size: 52px;
	color: #fff;
	font-weight: 700;
	margin-top: 10%;
}
.ipu{
	width: 95%;
	margin: 0 auto;

	
	background: #fff;
	border-radius: 8px;
	
	
	margin-top: 5%;
	padding: 4%;
}
.cjp{
	color: #333333;
	font-weight: 600;
}
.yuan{
	background: #F6F6F6;
	border-radius: 12px;
	
	width: 100%;
	height: 2.8rem;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding-left: 3%;
	padding-right: 6%;
	margin-top: 5%;
}
.hs{
	display: flex;
	align-items: center;
	width: 30%;
	    justify-content: flex-end;
}
.ip_yue{
	background-color: initial !important; 
	height: 100%;
	border: 0;
	color: #999999;
	width: 100%;
	font-size: 1rem;
	display: flex;
	align-items: center;
	border-bottom: 0 !important;
	border: 0 !important;
}
.close{
	width: 40px;
	height: 40px;
	
}
.KRW{
	color: #fff;

}
.xian2{
	width: 2px;
	height: 100%;
	background-color: #999999;
	height: 1rem;
	margin-left: 10%;
	margin-right: 10%;
}
.ti{
	width: 95%;
	margin: 0 auto;
	background: linear-gradient(90deg, #FF807B, #FF8FB8);
	border-radius: 10px;
	display: flex;
	align-items: center;
	justify-content: center;
	color: #fff;
	height: 3.5rem;
	margin-top: 5%;
}
.text{
	width: 95%;
	margin: 0 auto;
	margin-top: 8%;
	color: #FFFFFF;
	opacity: 0.5;
	font-size: 30px;
}
.rows{
	margin-bottom: 5%;
	margin-top: 5%;
	line-height: 2;
	padding-bottom: 20%;
}
.uu{
	margin-top: 5%;
	color: #b3aaba;
	font-size: 12px;
}
.duan{
	background-color: #FF1A6D;
	width: 8px;
	height: 40px;
	border-radius: 8px;
	margin-right: 3%;
}
.srf{
	display: flex;
	align-items: center;
}
.go{
	width: 15px;
	height: 20px;
}
.bank{
	display: flex;
	color: #fff;
	margin-top: 5%;
	align-items: center;
	justify-content: space-between;
	border-bottom: 1px solid #523669;
	padding-bottom: 5%;
}
.bank2{
	border: 0;
}
.lis{
	margin-bottom: 30px;
}
</style>
<style>
	.pp1 .van-field__control {
	  color: #000; /* 红色字体 */
	}
</style>

<style lang="less" scoped>
@import "../../assets/css/base.css";

.van-cell {
  font-size: 32px;
  line-height: 80px;
}

.van-hairline--bottom::after {
  border-bottom-width: 3px;
}

.bankbox {
  padding: 15px;
  color: #000;
  background-color: #fff;
}

.bankbox .title {
  padding: 8px 10px 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  font-size: 28px;
}

.main-box {
  background: #fff;
}

.main-box .label {
  padding: 20px;
  font-size: 35px;
  color: #797878;
}

::v-deep .van-picker__toolbar {
  height: 50px;
}

::v-deep .van-picker__cancel,
.van-picker__confirm {
  padding: 0 20px;
  font-size: 20px;
}

::v-deep .van-picker-column {
  font-size: 40px;
}

.main-box p {
  padding: 0 20px;
  font-size: 30px;
  color: #ee0a24;
}

.bindCard {
  margin: 20px 30px 0;
  height: 80px;
  line-height: 1.22667rem;
  border-radius: 50px;
  color: #fff;
  font-size: 30px;
  font-weight: bolder;
  border: none;
  background: linear-gradient(90deg, #e6c3a1, #7e5678);
}
</style>
